import React from "react"
import PageLayout from "../components/pageLayout"

const TermsOfUse = () => {
  return (
    <PageLayout seo={{ title: "Terms of use" }}>
      <h1>Terms of Use</h1>
      <p>
        By agreeing to these terms, you give us permission to use your story
        and/or photos in our communications for a period of three years. During
        this period you can choose to opt out at any time by emailing
        media@oruk.org
      </p>
      <p>We can use your submitted images/video recordings for:</p>
      <ul>
        <li>
          Publications: ORUK’s leaflets, posters, newsletters and other
          marketing material
        </li>
        <li>
          Social media: ORUK’s social media pages [Twitter, Facebook, Instagram,
          LinkedIn & Youtube]
        </li>
        <li>ORUK’s own website and associated sites such as ownpain.co.uk</li>
        <li>
          Print and online media: National, regional and local papers, magazines
          and news sites as long as for charitable purposes
        </li>
      </ul>
      <p>
        I am happy to give my consent to Orthopaedic Research UK and understand
        that:
      </p>
      <ul>
        <li>
          My images will be held in accordance with the General Data Protection
          Regulation 2018 (GDPR guidelines);
        </li>
        <li>
          The images of myself captured in the video recordings and/or
          photographs will be the copyright of ORUK and any other intellectual
          property which arises in the photographs/recordings will also belong
          to ORUK;
        </li>
        <li>
          I hereby agree to irrevocably assign all property rights in my
          photographs and/or recordings to ORUK;
        </li>
        <li>
          I can ask ORUK to stop using my images at any time by telephone or
          email, in which case efforts will be taken to prevent them being used
          in future digital and offline publications but they may continue to
          appear in publications already in circulation;
        </li>
      </ul>

      <p>
        If you are under 18, we need written permissions from a parent, guardian
        or responsible adult
      </p>

      <p>
        Please contact us at{" "}
        <a href="mailto:ownpain@oruk.org">ownpain@oruk.org</a> if you have any
        other preferences you would like to request (e.g. Please do not disclose
        my age, gender, children, partner etc.).
      </p>

      <p>
        For more details on how we use and look after your personal information,
        read our privacy policy at https://www.oruk.org/privacy-policy/
      </p>
      <br />
      <br />
      <h3>Sources</h3>
      <ul>
        <li>
          <a href="https://sarcoma.org.uk/sites/default/files/resources/media_constent_form_2.pdf">
            https://sarcoma.org.uk/sites/default/files/resources/media_constent_form_2.pdf
          </a>
        </li>
        <li>
          <a href="https://www.stroke.org.uk/sites/default/files/photo_and_video_consent_form_2019.pdf">
            https://www.stroke.org.uk/sites/default/files/photo_and_video_consent_form_2019.pdf
          </a>
        </li>
        <li>
          <a href="https://wenta.co.uk/gdpr-form-images-and-video-consent/">
            https://wenta.co.uk/gdpr-form-images-and-video-consent/
          </a>
        </li>
      </ul>
    </PageLayout>
  )
}

export default TermsOfUse
