import React from "react"
import SEO from "./seo"
import { Logo } from "../images"
import { Link } from "gatsby"

const PageLayout = props => {
  return (
    <>
      <SEO title={props.seo.title} description={props.seo.description} />
      <main>
        <div className="page-thanks page">
          <div className="wrapper">
            <div className="page-header">
              <div className="row">
                <div className="col col-2 col-empty"></div>
                <div className="col col-7">
                  <Link to="/" className="logo">
                    <img src={Logo} alt="logo" />
                    <div className="logo__line"></div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="page-content">
              <div className="row">
                <div className="col col-2 col-empty"></div>
                <div className="col col-7">{props.children}</div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default PageLayout
